import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import apartments_list from '../apartments_list';
import Map from './Map'
import Form from './Form';
import '../css/rooms.css'
import { GoVideo } from "react-icons/go";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import Carousel from './Carousel';

const find_minimum = (apart) => {
  var min = 99999
  apart.rooms.forEach((elem) => {
    if (elem.minimum < min)
      min = elem.minimum
  })
  return min
}

const is_apart_available = (apart) => {
  let ret = true
  apart.rooms.forEach((elem) => {
    if (elem.booked === true)
      ret = false
  })
  return ret
}

const calcul_apart_price = (apart) => {
  let price = 0
  apart.rooms.forEach((room) => {
    price += room.price
  })
  return price
}

const Rooms = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [popup, setPopup] = useState(false)
  const [thumbnail, setThumbnail] = useState(0)
  const [reservation, setReservation] = useState(["", 0])

  const tagConfig = {
    kitchen: { label: t("rooms.tags.kitchen") },
    rooftop: { label: t("rooms.tags.rooftop") },
    wifi: { label: t("rooms.tags.wifi") },
    washing: { label: t("rooms.tags.washing") },
    laundry: { label: t("rooms.tags.laundry") },
    dressing: { label: t("rooms.tags.dressing") },
    tv: { label: t("rooms.tags.tv") },
    ac: { label: t("rooms.tags.ac") },
    dish: { label: t("rooms.tags.dish") },
    keeper: { label: t("rooms.tags.keeper") },
    pool_int: { label: t("rooms.tags.pool_int") },
    pool_ext: { label: t("rooms.tags.pool_ext") },
    towels: { label: t("rooms.tags.towels") },
    fitness: { label: t("rooms.tags.fitness") },
    furnished: { label: t("rooms.tags.furnished") },
    sea_view: { label: t("rooms.tags.sea_view") },
    sauna: { label: t("rooms.tags.sauna") },
    ping_pong: { label: t("rooms.tags.ping_pong") },
    babyfoot: { label: t("rooms.tags.babyfoot") },
    tennis: { label: t("rooms.tags.tennis") },
    squash: { label: t("rooms.tags.squash") },
    garden: { label: t("rooms.tags.garden") }
  };

  const translatedApartments = apartments_list.map(apart => ({
    ...apart,
    name: t(apart.name),
    availability: t(apart.availability),
    keywordsApart: t(apart.keywordsApart),
    price: calcul_apart_price(apart),
    rooms: apart.rooms.map(room => ({
      ...room,
      name: t(room.name),
      availability: t(room.availability),
      description: t(room.description),
      keywords: t(room.keywords),
    })),
    keywords: t(apart.keywords),
    description: t(apart.description),
  }));
  const apart = translatedApartments.find(apart => apart.id.toString() === slug);

  useEffect(() => {
    if (!apart) {
      navigate("/apartments");
    }
  }, [apart, navigate]);

  const moveThumbnail = (num) => {
    const newThumbnail = thumbnail + num
    if (newThumbnail < 0)
      setThumbnail(apart.img.length - 1)
    else if (newThumbnail === apart.img.length)
      setThumbnail(0)
    else
      setThumbnail(newThumbnail)
  }

  const Includes = ({ rooms }) => {
    if (rooms > 4)
      return (
        <p style={{ color: "black" }}>{t('rooms.includes')}</p>
      )
    else
      return (
        <p style={{ color: "black" }}>{t('rooms.no-includes')}</p>
      )
  }

  if (!apart) return null;
  return (
    <div id='rooms'>
      <Form setPopup={setPopup} popup={popup} apart={apart} reservation={reservation} />
      <section className='images'>
        <h1>{apart.name}</h1>
        <div className='container'>
          <div className='photos'>
            <div className='photo'>
              {
                apart.img[thumbnail].endsWith('.mp4') ?
                  (<video src={apart.src + '/apart/' + apart.img[thumbnail]}
                    controls playsInline autoPlay></video>) :
                  (<img src={apart.src + '/apart/' + apart.img[thumbnail]} alt={apart.name} />)
              }
              <SlArrowLeft className='left-btn' onClick={() => {
                moveThumbnail(-1)
              }} />
              <SlArrowRight className='right-btn' onClick={() => {
                moveThumbnail(1)
              }} />
              <p className='index'><span className='text-color' style={{ fontWeight: 'bold' }}>
                {thumbnail + 1}</span>/{apart.img.length}
              </p>
            </div>
            <div className='slider'>
              <div className='thumbnails'>
                {
                  apart.img.map((img, index) => (
                    <div className='thumbnail mobile' key={index} onClick={() => setThumbnail(index)}>
                      {
                        img.endsWith('.mp4') ?
                          (<div style={{ position: 'relative', width: 'inherit', height: 'inherit' }}>
                            <img className='video' src={apart.src + "/apart/" + apart.img[0]} alt='thumbnail' />
                            <GoVideo className='icon' />
                          </div>) :
                          (<img src={apart.src + "/apart/" + img} alt='thumbnail' />)
                      }
                    </div>
                  ))
                }
                {
                  apart.thumbnailOrder.map((imgPos, index) => (
                    <div className='thumbnail pc' key={index} onClick={() => setThumbnail(imgPos - 1)}>
                      {
                        apart.img[imgPos - 1].endsWith('.mp4') ?
                          (<div style={{ position: 'relative', width: 'inherit', height: 'inherit' }}>
                            <img className='video' src={apart.src + "/apart/" + apart.img[0]} alt='thumbnail' />
                            <GoVideo className='icon' />
                          </div>) :
                          (<img src={apart.src + "/apart/" + apart.img[imgPos - 1]} alt='thumbnail' />)
                      }
                    </div>
                  ))
                }
              </div>
            </div>
          </div>

          <Map address={apart.address} />
        </div>
      </section>
      <section className='desc'>
        <h2 className='text-color'>{t('rooms.description')}</h2>
        <p>{apart.description}</p>
      </section>
      <section className='equipments'>
        <h2 className='text-color'>{t('rooms.equipments')}</h2>
        <div className="tags">
          {apart.equipment.map((tag, index) => (
            <div key={index} className="tag">
              {tagConfig[tag] ? (
                <>
                  <img
                    src={`/assets/icons/equipments/${tag}.png`}
                    alt={tagConfig[tag].label}
                  />
                  <p>{tagConfig[tag].label}</p>
                  {tagConfig[tag].icon}
                </>
              ) : (
                'nothing'
              )}
            </div>
          ))}
        </div>
      </section>
      <section className='rooms'>
        <h2 className='text-color'>{t('rooms.rooms')}</h2>
        {
          (apart.location_mode.length === 1 && apart.location_mode[0] === 'apartment') ?
            (<div>
              <div className='container'>
                {apart.rooms.map((room, index) => (
                  <div className='room entire' key={index}>
                    <p className='price'>
                      <span className='value'>{room.price}€/</span>
                      <span className='text-color'>{t("rooms.month")}</span>
                    </p>
                    <div className='photo'>
                      <Carousel src={apart.src + '/room' + (index + 1) + "/"} images={apart.rooms[index].img} />
                    </div>
                    <h3>{room.name}</h3>
                    <p className='description'>{room.description}</p>
                    <p className='keywords'>{room.keywords}</p>
                  </div>
                ))}
              </div>
              <div className='recap'>
                <h3>{t("rooms.price")}:</h3>
                {
                  apart.promotion ?
                    (<div>
                      <div className='promo-container'>
                        <p className='price1'>
                          <span>{apart.price}€</span>/{t('rooms.month')} {t('rooms.all-inclusive')}
                        </p>
                        <p className='promo'>-{apart.promotion}€</p>
                      </div>
                      <p className='price'>
                        <span>{apart.price - apart.promotion}€</span>/{t('rooms.month')} {t('rooms.all-inclusive')}
                      </p>
                    </div>) :
                    (<div>
                      <p className='price'>
                        <span>{apart.price}€</span>/{t('rooms.month')} {t('rooms.all-inclusive')}
                      </p>
                    </div>)
                }
                <p className='availability'>{apart.rooms[0].availability}</p>
                <Includes rooms={apart.rooms.length} />
                <p className='reservation' onClick={() => {
                  setPopup(true)
                  setReservation(["apart", 0])
                }}>{t("rooms.apart-reservation")}</p>
              </div>
            </div>) :
            (<div className='container'>
              {
                (apart.location_mode.length !== 1 && is_apart_available(apart)) ?
                  //APART
                  (<div className='room apart'>
                    {
                      apart.promotion ?
                        (<div className='price'>
                          <p className='promotion'>
                            <span className='value'>{apart.price}€/</span>
                            <span>{t("rooms.month")}</span>
                          </p>
                          <p>
                            <span className='value' style={{ color: 'black' }}>{apart.price - apart.promotion}€/</span>
                            <span className='text-color'>{t("rooms.month")}</span>
                          </p>
                        </div>) :
                        (<p className='price'>
                          <span className='value'>{apart.price}€/</span>
                          <span className='text-color'>{t("rooms.month")}</span>
                        </p>)
                    }
                    <div className='photo'>
                      <Carousel src={apart.src + '/apart/'} images={apart.img} />
                    </div>
                    <h3>{t('rooms.apart')}</h3>
                    <p className='description'>{apart['keywords-apart']}</p>
                    <div style={{ height: '80px' }}>
                      <p>{apart.availability}</p>
                      <p>Minimum {find_minimum(apart)} mois</p>
                    </div>
                    <p className='availability'>{apart.rooms[0].availability}</p>
                    <Includes rooms={apart.rooms.length} />
                    <p className='reservation' onClick={() => {
                      setPopup(true)
                      setReservation(["apart", 0])
                    }}>{t("rooms.apart-reservation")}</p>
                  </div>) : (<></>)
              }
              {
                apart.rooms.map((room, index) => (
                  room.booked ?
                    //BOOKED
                    (<div className='room booked' key={index}>
                      <div className='photo'>
                        <img src={apart.src + '/room' + (index + 1) + "/" + apart.rooms[index].img[0]} alt={apart.name} />
                        <p className='booked-text'>{t('rooms.booked')}</p>
                      </div>
                      <h3>{room.name}</h3>
                      <p className='description'>{room.description}</p>
                      <p className='keywords'>{room.keywords}</p>
                      <p className='availability'>{room.availability}</p>
                      <Includes rooms={apart.rooms.length} />
                      <p className='blocked'>{t("rooms.room-reservation")}</p>
                    </div>) :
                    //CLASSIC
                    (<div className='room' key={index}>
                      <p className='price'>
                        <span className='value'>{room.price}€/</span>
                        <span className='text-color'>{t("rooms.month")}</span>
                      </p>
                      <div className='photo'>
                        <Carousel src={apart.src + '/room' + (index + 1) + "/"} images={apart.rooms[index].img} />
                      </div>
                      <h3>{room.name}</h3>
                      <p className='description'>{room.description}</p>
                      <p className='keywords'>{room.keywords}</p>
                      <p className='availability'>{room.availability}</p>
                      <Includes rooms={apart.rooms.length} />
                      <p className='reservation' onClick={() => {
                        setPopup(true)
                        setReservation(["room", index])
                      }}>{t("rooms.room-reservation")}</p>
                    </div>)
                ))
              }
            </div>)
        }
      </section>
    </div>
  );
};

export default Rooms;
