import { Routes, Route, Navigate, useLocation} from 'react-router-dom';
import Nav from '../Nav'
import Home from '../Home'
import Apartments from '../apartments/Apartments';
import Rooms from '../apartments/Rooms';
import React, { useEffect, useState } from 'react'

function PublicRouter() {
  const [isIntersecting, setIsIntersecting] = useState(false)
  const location = useLocation()

  useEffect(() => {
    setIsIntersecting(false)
  }, [location, setIsIntersecting])
  
  return (
    <div style={{position: 'relative'}}>  
        <Routes>
          <Route element={<Nav isIntersecting={isIntersecting} />}>
          <Route path="/" exact element={<Home onIntersectionChange={setIsIntersecting} />} />
          <Route path="/apartments" exact element={<Apartments />} />
          <Route path="/apartments/:slug" exact element={<Rooms />} />
          <Route path="*" element={<Navigate to='/'/>}/>   
          </Route>
        </Routes>
    </div>
  );
}

export default PublicRouter;