
const sliderParams = [
    {
        "img": "/assets/home/carrousel/1.jpeg",
    },
    {
        "img": "/assets/home/carrousel/2.jpeg"
    },
    {
        "img": "/assets/home/carrousel/3.jpg"
    },
    {
        "img": "/assets/home/carrousel/4.jpeg"
    },
    {
        "img": "/assets/home/carrousel/5.jpg"
    },
    {
        "img": "/assets/home/carrousel/6.jpeg"
    },
]

export default sliderParams