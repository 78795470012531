import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import PublicRouter from './routers/PublicRouter'
//import AdminRouter from './routers/AdminRouter'

export default function App () {
    return(
        <div className='App'>
            <Router>
                <Routes>
                    <Route path="/*" element={<PublicRouter />}/>
                </Routes>
            </Router>
        </div>
    )
}