import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import { useInView } from "react-intersection-observer";
import Footer from './Footer';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import sliderParams from './sliderParams'
import './css/home.css'

const Slider = () => {
  const [slideIndex, setSlideIndex] = useState(0)
  const sliderRef = useRef(null)

  return (
    <div className='slider-control'>
      <div className='slider'>
        <div className="slider-container" ref={sliderRef}>
          {
            sliderParams.map((slide, key) => (
              <div className='image' key={key}>
                <Link to='/apartments'>
                  <img src={slide.img} alt="apartment" />
                </Link>
              </div>
            ))
          }
        </div>
      </div>
      <SlArrowLeft className='left-btn' onClick={() => {
        sliderRef.current.scrollLeft -= 350
      }} />
      <SlArrowRight className='right-btn' onClick={() => {
        sliderRef.current.scrollLeft += 350
      }} />
      <div className='dots'>
        {/*
          sliderParams.map((useless, key) => (
            <div className={slideIndex === key ? "active" : ""} key={key}></div>
          ))
        */}
      </div>
    </div >
  );
}

const Home = ({ onIntersectionChange }) => {
  const { t } = useTranslation();

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    onIntersectionChange(inView);
  }, [inView, onIntersectionChange])

  return (
    <div id="home">
      <section className='landing'>
        <div className="bg" style={{ backgroundImage: `url(/assets/home/landing.png)` }}></div>
        <div className='container' ref={ref}>
          <p className="text">{t("home.landing")} <span className='text-color'>{t("home.chili")}</span></p>
          <Link to='/apartments' className='btn'>{t('home.search-btn')}</Link>
        </div>
      </section>

      <section className='benefits'>
        <div className='without'>
          <h1>{t('home.without-title')}</h1>
          <ul>
            <li><img src="/assets/icons/cross.png" alt='cross' /><p>{t('home.without1')}</p></li>
            <li><img src="/assets/icons/cross.png" alt='cross' /><p>{t('home.without2')}</p></li>
            <li><img src="/assets/icons/cross.png" alt='cross' /><p>{t('home.without3')}</p></li>
            <li><img src="/assets/icons/cross.png" alt='cross' /><p>{t('home.without4')}</p></li>
            <li><img src="/assets/icons/cross.png" alt='cross' /><p>{t('home.without5')}</p></li>
            <li><img src="/assets/icons/cross.png" alt='cross' /><p>{t('home.without6')}</p></li>
          </ul>
        </div>
        <div className='with'>
          <h1>{t('home.with-title')}</h1>
          <ul>
            <li><img src="/assets/icons/check.png" alt='check' /><p>{t('home.with1')}</p></li>
            <li><img src="/assets/icons/check.png" alt='check' /><p>{t('home.with2')}</p></li>
            <li><img src="/assets/icons/check.png" alt='check' /><p>{t('home.with3')}</p></li>
            <li><img src="/assets/icons/check.png" alt='check' /><p>{t('home.with4')}</p></li>
            <li><img src="/assets/icons/check.png" alt='check' /><p>{t('home.with5')}</p></li>
            <li><img src="/assets/icons/check.png" alt='check' /><p>{t('home.with6')}</p></li>
          </ul>
        </div>
      </section>

      <section className='find'>
        <h1>{t("home.find-title1")} <span className='text-color'>{t("home.find-title2")}</span></h1>
        <div className='container'>
          <img src='/assets/home/steps.jpg' alt='steps' />
          <div className='steps-container'>
            <div className='steps'>
              <img className='arrow1' src='/assets/icons/arrow.png' alt='arrow' />
              <img className='step-img' src='/assets/icons/1.png' alt='step-one' />
              <div>
                <h2>{t("home.step1-text1")}</h2>
                <p>{t("home.step1-text2")}</p>
              </div>
            </div>
            <div className='steps'>
              <img className='arrow2' src='/assets/icons/arrow.png' alt='arrow' />
              <img className='step-img' src='/assets/icons/2.png' alt='step-two' />
              <div>
                <h2>{t("home.step2-text1")}</h2>
                <p>{t("home.step2-text2")}</p>
              </div>
            </div>
            <div className='steps'>
              <img className='step-img' src='/assets/icons/3.png' alt='step-three' />
              <div>
                <h2>{t("home.step3-text1")}</h2>
                <p>{t("home.step3-text2")}</p>
              </div>
            </div>
            <Link to='/apartments' className='btn'>{t("home.search-btn")}</Link>
          </div>
        </div>
      </section>

      <section className='apartments' style={{ backgroundImage: ` url(/assets/home/apartments.jpg) ` }}>
        <h1>{t("home.apartments-title1")} <span className='text-color'>{t("home.apartments-title2")}</span></h1>
        <Slider />
        <Link to='/apartments' className='btn'>{t('home.apartments-btn')}</Link>
      </section>

      <section className='cities'>
        <div>
          <div className='photo'><img src="/assets/home/cities.jpg" alt="cities" /></div>
          <div className='container'>
            <h1 className='text-color'>{t("home.cities-title")}</h1>
            <h2>{t("home.cities-subtitle")}</h2>
            <p>{t("home.cities-text")}</p>
          </div>
          <Link to='/apartments' className='btn'>{t('home.cities-btn')}</Link>
        </div>
      </section>

      <section className='include'>
        <h1>{t('home.include-title')} <span className='text-color'>Flatmaters</span></h1>
        <div>
          <div className='container'>
            <div className='icon'><img src="/assets/icons/sim.png" alt="sim" /></div>
            <div>
              <h2 className='text-color'>{t('home.include-sim-title')}</h2>
              <p className='text'>{t('home.include-sim-text')}</p>
            </div>
          </div>
          <div className='container'>
            <div className='icon'><img src="/assets/icons/discounts.png" alt="discounts" /></div>
            <div>
              <h2 className='text-color'>{t('home.include-discounts-title')}</h2>
              <p className='text'>{t('home.include-discounts-text')}</p>
            </div>
          </div>
          <div className='container'>
            <div className='icon'><img src="/assets/icons/pack.png" alt="welcome-pack" /></div>
            <div>
              <h2 className='text-color'>{t('home.include-pack-title')}</h2>
              <p className='text'>{t('home.include-pack-text')}</p>
            </div>
          </div>
        </div>
      </section>
      <section className='creators'>
        <h1>{t("home.creators-title1")} <span className='text-color'>{t("home.creators-title2")}</span></h1>
        <div>
          <div className='photo'><img src="/assets/home/team.jfif" alt="team" /></div>
          <div className='container'>
            <h3>Matt, Antoine, Carlota</h3>
            <h4>FLATMATERS CREATORS</h4>
            <p>{t("home.creators-text")}</p>
            <img src='/assets/home/signatures.png' alt='signatures' />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Home