import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom'
import { TbSwitchHorizontal } from "react-icons/tb";
import '../css/apartments.css'
import apartments_list from '../apartments_list';

const find_available_rooms = (apart) => {
    var counter = 0
    apart.rooms.forEach((elem) => {
        if (elem.booked === false)
            counter += 1
    })
    return counter
}

const find_minimum = (apart) => {
    var min = 99999
    apart.rooms.forEach((elem) => {
        if (elem.minimum < min)
            min = elem.minimum
    })
    return min
}

const find_minimum_price = (apart) => {
    var min = 99999
    apart.rooms.forEach((elem) => {
        if (elem.price < min)
            min = elem.price
    })
    return min
}

const is_only_apart = (mode) => {
    if (mode.length === 1)
        if (mode[0] === "apartment")
            return true
    return false
}

const calcul_apart_price = (apart) => {
    let price = 0
    apart.rooms.forEach((room) => {
        price += room.price
    })
    return price
}

const calcul_price_promotion = (apart) => {
    let price = (apart.price - apart.promotion) / apart.rooms.length
    if (Number.isInteger(price))
        return price
    else
        return price.toFixed(2)
}

const Apartments = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [popup, setPopup] = useState(true)
    const [destination, setDestination] = useState("Santiago")

    const translatedApartments = apartments_list.map(apart => ({
        ...apart,
        name: t(apart.name),
        availability: t(apart.availability),
        price: calcul_apart_price(apart),
        rooms: apart.rooms.map(room => ({
            ...room,
            name: t(room.name),
            availability: t(room.availability),
            description: t(room.description),
            keywords: t(room.keywords),
        })),
        keywords: t(apart.keywords),
        description: t(apart.description),
    }));

    return (
        <div id="apartments">
            <div className={popup ? "popup" : "none"}>
                <div>
                    <h1>{t('apartments.popup1')} <span className='text-color'>{t('apartments.popup2')}</span></h1>
                    <p onClick={
                        () => {
                            setDestination("Santiago")
                            setPopup(false)
                        }}>Santiago de Chile</p>
                    <p onClick={() => {
                        setDestination("Valparaíso")
                        setPopup(false)
                    }}>Viña del Mar/Valparaíso</p>
                </div>
            </div>
            <div className='switch'>
                <TbSwitchHorizontal className='icon' onClick={() => {
                    if (destination === 'Santiago')
                        setDestination("Valparaíso")
                    else
                        setDestination('Santiago')
                }} />
                <p>{destination === "Santiago" ? "Santiago de Chile" : "Viña del Mar/Valparaíso"}</p>
            </div>
            <h1>{t('apartments.title1')} <span className='text-color'>{t('apartments.title2')}</span></h1>
            <div className='container'>
                {
                    translatedApartments.filter(apart => apart.destination === destination).map((elem, key) => (
                        find_available_rooms(elem) === 0 ?
                            //COMPLETE
                            (<div className='apartment full' key={key} onClick={() => navigate("/apartments/" + elem.id)}>
                                <div className='photo'>
                                    <img src={elem.src + "/apart/" + elem.img[0]} alt={elem.name} />
                                    <p className='full-text'>{t('apartments.full')}</p>
                                </div>
                                <h2>{elem.name}</h2>
                                <p className='keywords'>{elem.keywords}</p>
                                <p>{elem.availability}</p>
                                <p>Minimum {find_minimum(elem)} mois</p>
                            </div>) : is_only_apart(elem.location_mode) ?
                                //ONLY APARTMENT LOCATION
                                (<div className='apartment only' key={key} onClick={() => navigate("/apartments/" + elem.id)}>
                                    <div className='photo'>
                                        <img src={elem.src + "/apart/" + elem.img[0]} alt={elem.name} />
                                        <div className="only">
                                            <p>{t('apartments.only_apart')}</p>
                                        </div>
                                    </div>
                                    <h2>{elem.name}</h2>
                                    <p className='keywords'>{elem.keywords}</p>
                                    <p>{elem.availability}</p>
                                    <p>Minimum {find_minimum(elem)} mois</p>
                                    <p className='price2'><span>{elem.price}€</span>/mois (tout inclus)</p>
                                    <p className='price3'><span>{calcul_price_promotion(elem)}€</span>/chambre/mois (tout inclus)</p>
                                    <p className='promotion'>-{elem.promotion}€</p>
                                </div>
                                ) :
                                //CLASSIC
                                (<div className='apartment' key={key} onClick={() => navigate("/apartments/" + elem.id)}>
                                    <p className='available'><span className='text-color'>{find_available_rooms(elem)}</span> {find_available_rooms(elem) !== 1 ? t('apartments.many-rooms') : t('apartments.one-room')}</p>
                                    <div className='photo'><img src={elem.src + "/apart/" + elem.img[0]} alt={elem.name} /></div>
                                    <h2>{elem.name}</h2>
                                    <p className='keywords'>{elem.keywords}</p>
                                    <p>{elem.availability}</p>
                                    <p>{t('apartments.minimum')} {find_minimum(elem)} {t('rooms.month')}</p>
                                    <p className='price1'>{t('apartments.from')}</p>
                                    <p className='price2'><span>{find_minimum_price(elem)}€ </span>
                                    {t('rooms.month')}/ {t('rooms.all-inclusive')}
                                    </p>
                                </div>)

                    ))
                }
            </div>
        </div>
    )
}

export default Apartments