import React, { useState } from 'react';
import { RxCross2 } from "react-icons/rx";
import { useTranslation } from "react-i18next";
import emailjs from 'emailjs-com';

const Form = ({ setPopup, popup, apart, reservation }) => {
    const { t } = useTranslation();
    const [confirm, setConfirm] = useState(false)
    const [inputType, setInputType] = useState('text');
    const initFormValue = {
        name: '',
        lastname: '',
        date: '',
        email: '',
        phone: ''
    }
    const [formValues, setFormValues] = useState(initFormValue);

    const sendEmail = (e) => {
        const dataToSend = {
            ...formValues,
            apart: apart.name,
            room: reservation[0] === 'room' ? apart.rooms[reservation[1]].name : "Appartement entier" 
        }
        emailjs.send("service_l9wreox", "template_vlh51po", dataToSend, "Vfx9JVg8EfgqXQ2_F").then(
            (result) => {
                console.log(result)
                setConfirm(true)
                setFormValues(initFormValue)
            },
            (error) => {
                console.log(error.text);
            }
        );
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const allFieldsFilled = Object.values(formValues).every((value) => value !== '');
        if (allFieldsFilled) {
            setPopup(false)
            sendEmail(e)
        } else {
            alert('Veuillez remplir tous les champs.');
        }
    };

    return (
        <div>
            <div className={popup ? "popup" : "none"}>
                <div style={{ position: 'relative' }}>
                    <RxCross2 className='icon' onClick={() => setPopup(false)} />
                    <h1>{t('rooms.popup.title1')} <span className='text-color'>{t('rooms.popup.title2')}</span></h1>
                    <p className='text'>{t('rooms.popup.text')}</p>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <input type='text' name='name' placeholder={t('rooms.popup.name')}
                                value={formValues.name}
                                onChange={handleInputChange} />
                            <input type='text' name='lastname' placeholder={t('rooms.popup.lastname')}
                                value={formValues.lastname}
                                onChange={handleInputChange} />
                        </div>
                        <input type={inputType} name='date' placeholder={t('rooms.popup.date')}
                            onFocus={() => setInputType('date')}
                            onBlur={() => setInputType('text')}
                            value={formValues.date}
                            onChange={handleInputChange} />
                        <input type='text' name='email' placeholder={t('rooms.popup.email')}
                            value={formValues.email}
                            onChange={handleInputChange} />
                        <input type='tel' name='phone' placeholder={t('rooms.popup.phone')}
                            value={formValues.phone}
                            onChange={handleInputChange} />
                        <button type='submit' className='send'>{t('rooms.popup.send')}</button>
                    </form>
                </div>
            </div>
            <div className={confirm ? "popup" : "none"}>
                <div style={{ position: 'relative' }}>
                    <RxCross2 className='icon' onClick={() => setConfirm(false)} />
                    <h1>{t('rooms.confirm.title')}</h1>
                    {
                        reservation[0] === "room" ?
                            (<p className='text1'>
                                {t('rooms.confirm.text1-room')}
                                <span className='name'>{apart.rooms[reservation[1]].name}</span>
                                {t('rooms.confirm.text2-room')}
                                <span className='name'>{apart.name}</span>
                                {t('rooms.confirm.text1')}.
                            </p>) :
                            (<p className='text1'>
                                {t('rooms.confirm.text-apart')}
                                <span className='name'>{apart.name}</span>
                                {t('rooms.confirm.text1')}.
                            </p>)
                    }
                    <p className='text1'>
                        {t('rooms.confirm.text2')}
                        <span className='here'>{t('rooms.confirm.here')}</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Form