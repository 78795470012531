import React, { useState, useRef, useEffect } from 'react';
import '../css/carousel.css';

const Carousel = ({ src, images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const carouselRef = useRef(null);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.scrollTo({
                left: carousel.clientWidth * currentIndex,
                behavior: 'auto'
            });
        }
    }, [currentIndex]);

    return (
        <div className={`carousel-container ${isFullscreen ? 'fullscreen' : ''}`}>
            <div
                className="carousel"
                ref={carouselRef}
                onClick={() => setIsFullscreen(true)}
            >
                {images.map((image, index) => (
                    <div className="carousel-slide" key={index}>
                        {image.endsWith('.mp4') ? (
                            <video src={`${src}${image}`} autoPlay muted loop 
                            className='carousel-image'/>
                        ) : (
                            <img
                                src={`${src}${image}`}
                                alt={`Slide ${index + 1}`}
                                className='carousel-image'
                            />
                        )}
                    </div>
                ))}
            </div>
            {isFullscreen && (
                <button
                    className="close-fullscreen"
                    onClick={(e) => {
                        e.stopPropagation(); // Empêche de fermer immédiatement en cliquant sur le carrousel
                        setIsFullscreen(false);
                    }}
                >
                    ✖
                </button>
            )}
            <div className="carousel-indicators">
                {images.map((_, index) => (
                    <button
                        key={index}
                        className={`indicator ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => setCurrentIndex(index)}
                    />
                ))}
            </div>
            <button onClick={goToPrevious} className="carousel-button prev">◀</button>
            <button onClick={goToNext} className="carousel-button next">▶</button>
        </div>

    );
};

export default Carousel;
