import React from 'react';

const Map = ({ address }) => {
  const apiKey = 'AIzaSyDmg_rYNj5hshnH6jn9jPlKNdbfl00ttEw'
  //const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  let mapUrl = `https://www.google.com/maps/embed/v1/`;

  if (address) {
    mapUrl += `place?key=${apiKey}&q=${encodeURIComponent(address)}`;
  }

  return (
    <iframe
      src={mapUrl}
      allowFullScreen
      loading="lazy"
      title="Google Map Embed"
      className='map'
    ></iframe>
  );
};

export default Map;
