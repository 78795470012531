import React from 'react'
import { useTranslation } from "react-i18next";
import { FaWhatsapp, FaFacebook, FaInstagram } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { Link } from 'react-router-dom'
import "./css/footer.css"

const Footer = () => {
    const { t } = useTranslation();

    return (
        <div id='footer'>
            <div className='bg' style={{ backgroundImage: `url(/assets/footer.png)` }}></div>
            <footer>
                <img className='logo' src='/assets/logo.png' alt='logo' />
                <div className='container'>
                    <h3>{t('footer.contact')}</h3>
                    <div className='contact'>
                        <FaWhatsapp className='whatsapp icon' />
                        <p>+33 769 569 801</p>
                    </div>
                    <div className='contact'>
                        <MdOutlineEmail className='email icon' />
                        <p>contact@flatmaters.com</p>
                    </div>
                </div>
                <div className='container'>
                    <h3>{t('footer.social')}</h3>
                    <div className='social'>
                        <a target='_blank' href='https://web.facebook.com/groups/valparaisostudent'><FaFacebook className='fb icon' /></a>
                        <a target='_blank' href='https://www.instagram.com/flatmaters/'><FaInstagram className='instagram icon' /></a></div>
                </div>
                <div className='container'>
                    <h3 className='adventure'>{t('footer.adventure')}</h3>
                    <Link to='/apartments' className='btn'>{t('footer.accommodation')}</Link>
                </div>
            </footer>
            <p>Copyright ©2024 Flatmaters. {t('footer.copyright')}.</p>
        </div>
    )
}

export default Footer